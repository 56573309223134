:root {
    --blue: #008ae6;
    --yellow: #ffd300;
    --primarycolor: #fda50f;
}

body{
    margin:0;
    padding:0;
    overflow:auto;
    /* height:100vh; */
    font-family: 'Poppins', sans-serif;
    background-image: linear-gradient( 
        105.3deg
         , rgb(30, 39, 107) 21.8%, rgb(77, 118, 221) 100.2%); 
    color: #fff;
}
h4  {
    color: #fff  !important;
}
 span {
    color: #fff !important;

}
.overlay{
    display: none;
}
div,section{
    box-sizing: border-box;
}
a,a:hover{
    text-decoration: none;
}
ul{
    margin:0;
    padding:0;
    list-style: none;
}
.header-banner{
    position: relative;
    margin-top:61px;
    height: calc(100vh - 61px);
}
.header-banner img{
    width:100%;
    height:100%;
    display: block;
}
.logo img{
    /* width: 280px; */
    height: 100px;
}
.logo a{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.header{
    display:flex;
    position:fixed;
    margin: auto;
    right: 0;
    left: 0;
    align-items: center;
    padding: 0 40px;
    top: 0;
    background:var(--primarycolor);
    z-index:1;
    justify-content: space-between;
    box-shadow: 0 0px 1px rgb(0 0 0 / 20%), 0 4px 10px rgb(0 0 0 / 20%);
}
.header > ul{
    display: flex;
    width:100%;
    justify-content: flex-end;
}
.header > ul li{
    position: relative;
}
.header > ul li a:hover{
    color: var(--blue);
}
.header > ul li a{
    font-size: 16px;
    color:#fff;
    /* display: block; */
    display: inline-block;
    padding: 20px 20px;
}
.header > ul li a span {
    position: relative;
    line-height: 0 !important;
    overflow: inherit !important;
    left: 10px;
}

.header > ul li .dropdown{
    position: absolute;
    width:280px;
    padding: 8px 0px;
    /* left:50%; */
    border-radius:  15px;
    /* margin-left: -140px; */
    overflow: hidden;
    box-shadow: 0 0px 1px rgb(0 0 0 / 20%), 0 4px 10px rgb(0 0 0 / 20%);
    display: none;
    background-color: #fff;
    border-top:3px solid var(--blue);
}
.header > ul li .dropdown ul li{
    margin-right: 0;
}
.header > ul li .dropdown ul li a{
    color: #008ae6;
    padding: 8px 16px;
    font-size: 15px;
    text-align: left;
}
.header > ul li:hover .dropdown{
    display: block;
}
.header > ul li .dropdown ul li:hover{
    color:#fff !important;
    /* background:var(--blue); */
    background-image: linear-gradient( 
        105.3deg
         , rgb(30, 39, 107) 21.8%, rgb(77, 118, 221) 100.2%); 
}

.header > ul li .dropdown ul li:hover a{
    color:#fff !important;
}

.banner-content{
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 9;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 0 40px;
}

.banner-content h2{
    font-size: 50px;
    color:#fff;
    margin:0;
}
.banner-content h3{
    font-size: 25px;
    color: var(--primarycolor);
    margin:0;
}
.bottom-buttons{
    display: flex;
    margin-top: 30px;
    width: 100%;
    justify-content: flex-end;
}
.bottom-buttons a{
    background-color: #fff;
    border-radius: 4px;
    padding: 14px 40px;
    color: var(--primarycolor);
    font-size: 16px;
    margin-left: 15px;
    font-weight: 600;
}
.bottom-buttons a:hover{
    background-color: var(--primarycolor);
    color:#fff;
}
.logic-provider-section{
    display: flex;
    flex-direction:column;
    padding:50px;
    justify-content: space-between;
}
.content-left{
    width:60%;
    text-align: left;
    box-sizing: border-box;
    flex:1;
}
.content-left h4{
    font-size: 24px;
    color:#000;
    margin: 0;
    padding: 0px 18px;

}
.content-left span{
    font-size: 16px;
    color: #000;
    display: block;
    margin-top: 20px;
    line-height: 24px;
    padding: 0px 18px;
}
.about-us-section > div{
    flex: unset;
}
.about-us-section{
    display:flex;
}
.image-right{
    width:40%;
    flex:1;
}
.service-section{
    padding: 50px;
    margin-top: 61px;
    align-items: flex-start;
    flex-direction: row;
    min-height: calc(100vh - 33px);

}
.service-section > div{
    padding:0;
}
.image-right img{ 
    width:100%;
    height: 100%;
    object-fit: cover;
    display: block;
}
.our-services-section{
   display:flex;
   flex-direction: column;
   padding:50px;

}

.our-services-section h2{
    width:100%;
    text-align: center;
    margin:0;
    font-size:30px;
    padding-bottom: 40px;
}
.card-container{
    display: flex;
    justify-content: space-between;
}
.services-box{
    width: 24%;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0px 1px rgb(0 0 0 / 20%), 0 4px 10px rgb(0 0 0 / 20%);
}
.image-wrapper{
    height:240px;
}
.image-wrapper img{
    width:100%;
    height:100%;
}
.box-content{
    padding: 20px;
}
.arrow-down{
    /* display: none; */
    color: #fff;
}
.box-content h4{
    margin: 0;
    height: 50px;
    font-size: 20px;
    padding-bottom: 18px;
    color: #000 !important;
}
.box-content a{
    display: inline-flex;
    padding: 10px 30px;
    color: var(--primarycolor);
    font-weight: 600;
    margin: auto;
    border: 1px solid;
    width: auto;
    border-radius: 4px;
    transition: 0.3s background-color linear;
}
.box-content a:hover{
    color:#fff;
    background-color: var(--primarycolor);
}
.white-bg{
    background: #fff;
}
.clients-logo{
    
    justify-content: space-between;
}
.clients-logo img{ 
    width: 22%;
    height: 180px;
    object-fit: cover;
}
.clients-speaker-block{
  display:flex;
  justify-content: space-between;  
  flex-wrap: wrap;
  overflow: hidden;
}
.our-services-section .slick-slide{
    margin:0 25px;
    height: 100%;
    display: flex !important;
}
.our-services-section .slick-dots li button:before{
  display:none;
}
.our-services-section .slick-dots li button{
    padding:0;
}
.our-services-section .slick-dots li{
    width:12px;
    height:12px;
    background:#3ab54a26;
    border-radius: 50%;
}
.our-services-section .slick-dots li.slick-active{
    background:#008ae6;
}
.our-services-section .slick-slide div{
    height: 100%;
}
.our-services-section .slick-list{
  padding:30px 80px !important;
}
.our-services-section .slick-track{
 display:flex;
}

.speaker-card{
    box-sizing: border-box;
    padding: 40px;
    box-shadow: 0 0px 1px rgb(0 0 0 / 20%), 0 4px 10px rgb(0 0 0 / 20%);
    overflow: hidden;
    border-radius: 0 20px 0 20px;
    background-color: #fff;
    color: #000;
    display: flex;
    position: relative;
    flex-direction: column;
    outline:0;
}
.speaker-card h4{
    margin:0;
    padding-bottom: 30px;
    font-size:15px;
    font-weight: 500;
}
.speaker-card span{
    width:100%;
    color: var(--primarycolor);
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    font-size: 16px;
    font-weight: 600;
}
.contact-us{
    /* background: var(--primarycolor) !important; */
    background-image: linear-gradient( 
        105.3deg
         , rgb(30, 39, 107) 21.8%, rgb(77, 118, 221) 100.2%); 
    padding: 12px 40px;
    justify-content: space-between;
    align-items: center;
}

.footer h4{
    color: #fff;
}

.contact-details{
    display: flex;
}
.contact-details ul{
    display: flex;
    margin: auto;
    margin-top: 20px;
}

.contact-details ul li{
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.contact-details ul li h4{
    margin: 0;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.contact-details ul li p{
   margin:0;
   font-size: 14px;
   /* padding-left: 10px; */
   color:#fff;
}
.contact-details ul li h4 span{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: center;
    border-radius: 50%;
    color: var(--primarycolor) !important;
    margin: 5px;
}
.contact-details ul li a span{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: center;
    border-radius: 50%;
    color: var(--primarycolor) !important;
    margin: 5px;
}
.contact-details ul li span i{
    font-size: 20px;
}
.footer-logo{
    display: flex;
} 
.footer-logo img{
    width:180px;
}
.span-text {
    width: 100%;
    float: none;
    font-size: 0.875em;
    margin-top: 25px;
    line-height: 25px;
    margin-bottom: 15px;
}
.span-text h4{
    margin:0;
    font-size: 16px;
    padding-bottom: 10px;
}
.list-content{
    margin-left: 15px;
    list-style: inherit;
}
.list-content li {
    position: relative;
    padding-left: 17px;
    float: none;
    margin-top: 10px;
}
.header-banner .slick-slider{
    overflow: hidden;
    height:100%;
}
.header-banner .slick-list{
    height:100%;
}
.header-banner .slick-slide{
    position: relative;
    height:100%;
}
.header-banner .slick-track{
    height:100%;
}
.header-banner .slick-track > div{
    height:100%;
}
.header-banner .slick-slide > div{
    height:100%;
}
.header-banner .slick-slide > div > div{
    height:100%;
} 

.header-banner .slick-dots{
    bottom:10px;
}

.header-banner .slick-dots li button::before{
    width:30px;
    height:30px;
}
.header-banner .slick-dots li button::before{
    color:#fff;
    opacity: 1;
}
.header-banner .slick-dots li.slick-active button::before{
   color:var(--primarycolor);
   opacity: 1;
}
.menu-icon{
    display: none;
}
.closeMenu{
    display: none;
}

.column {
    float: left;
    width: 32.3%;
    margin-bottom: 16px;
    padding: 0 30px;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 8px;
}

.network-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.network-image {
    width: '100%';
    min-height: "257px";
}

.card img {
    width: '100%'
}

.about-section {
    /* padding: 50px;
    text-align: center;
    background-color: #33a041;
    color: white; */
    
    width: 100%;
    text-align: left;
    box-sizing: border-box;
    margin: auto;

}

.about-section h4{
    font-size: 26px;
    color:#fff;
    margin: 0;
}

.about-section h2{
    color:#fff;
}

.about-section span{
    font-size: 16px;
    color: #fff;
    display: block;
    margin-top: 20px;
    line-height: 24px;
}

.about-container {
    padding: 0 16px;
}

.about-container::after, .row::after {
    content: "";
    clear: both;
    display: table;
}

.team-title {
    color: #008ae6;
}

.contact-button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
}

.contact-button:hover {
    background-color: #555;
}
/* .get-quote{
    background: #fff;
}
.get-quote a{
    color:#33a041 !important;
}
.get-quote:hover{
    background: #fff !important;  
}
.get-quote:hover a{
    color:#33a041 !important;
} */

/* .contact-page{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */

.contact-in{
    width: 80%;
    height: auto;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px #666;
}

.contact-map{
    width: 100%;
    height: auto;
    flex: 50%;
}

.contact-map iframe{
    width: 100%;
    height: 100%;
}

.contact-form{
    width: 100%;
    height: auto;
    padding: 30px;
    flex: 50%;
    text-align: center;
}

.contact-form h1{
    margin-bottom: 10px;
    color: #000 !important;

}

.contact-form-txt{
    width: 100%;
    height: 40px;
    color: #000 !important;
    border: 1px solid #bcbcbc;
    border-radius: 50px;
    outline: none;
    margin-bottom: 20px;
    padding: 8px;
}

.contact-form-txt::placeholder{
    color: #aaa;
}

.contact-form-textarea{
    width: 100%;
    height: 130px;
    color: #000;
    border: 1px solid #bcbcbc;
    border-radius: 10px;
    outline: none;
    margin-bottom: 20px;
    padding: 8px;
}

.contact-form-textarea::placeholder{
    color: #aaa;
}

.contact-form-btn{
    width: 100%;
    border: none;
    outline: none;
    border-radius: 50px;
    background: #33a041;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 0;
    cursor: pointer;
    font-size: 18px;
}

.quote{
    width: 100%;
    height: auto;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px #666;
}

/* .quote-img{
    width: 50%;
    height: auto;
    float: left;
}

.quote-img img{
    width: 100%;
} */

.quote-form{
    width: 100%;
    height: auto;
    /* padding: 30px; */
    text-align: center;
    float: left;
    background-image: url(../images/quote.png);
    background-size: cover;
    background-position: center center;
}

.quote-form form{
    width: 50%;
    margin: 30px auto;
    background-color: #fff;
    padding: 15px;
    padding-right: 31px;
    box-shadow: 0 0px 1px rgb(0 0 0 / 20%), 0 4px 10px rgb(0 0 0 / 20%);
    border-radius: 4px;
}

.quote-form h1{
    margin-bottom: 15px;
    margin-top: 0;
    color: #000 !important;

}

.quote-form-txt{
    width: 100%;
    height: 50px;
    color: #000;
    border: 1px solid #bcbcbc;
    border-radius: 50px;
    outline: none;
    margin-bottom: 20px;
    padding: 8px;
    background: #fff;
}

.quote-form-label{
    color: #aaa;
}

.quote-form-txt::label{
    color: #aaa;
}

.quote-form-textarea{
    width: 100%;
    height: 130px;
    color: #000;
    border: 1px solid #bcbcbc;
    border-radius: 10px;
    outline: none;
    margin-bottom: 20px;
    padding: 8px;
}

.quote-form-textarea::placeholder{
    color: #aaa;
}

.quote-form-btn{
    width: 50%;
    border: none;
    outline: none;
    border-radius: 50px;
    background: var(--primarycolor);
    color: #fff;
    text-transform: uppercase;
    padding: 10px 0;
    cursor: pointer;
    font-size: 18px;
}

/* New CSS */

.banner-content-wrapper{
    background-size: cover;
    width: 100%;
    height: 100%;
    background-position: center center;
}

.three-line-text{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    min-height: 75px;
}
.cl{
    clear: both;
}
.left-footer{
    text-align: left;
    float: left;
    color: #fff;
    width: 50%;
}
.right-footer{
    float: left;
    width: 50%;
}
.footer-social-media-icons a{
    margin-right: 15px;
}
.footer-social-media-icons i{
    color: #fff;
    font-size: 24px;
}
.right-footer ul{
    text-align: left;
    float: left;
}
.right-footer ul:not(:last-child){
    margin-right: 50px;
}
.right-footer a{
    color: #fff !important;
}
.right-footer p{
    color: #fff !important;
    font-weight: bold;
}

.showDesktop{
    display: initial;
}
.showMobile{
    display: none;
}
.gst-state-name-column{
    min-width: 125px;
}
.main-heading{
    font-size: 30px;
}

.gradient-blue {
    background-image: linear-gradient( 
        105.3deg
         , rgb(30, 39, 107) 21.8%, rgb(77, 118, 221) 100.2%); 
}
.slick-slide img {
    height: 120px !important;
}

@media (max-width:550px) {
.header>ul li a {
        display: flex !important;

 }
}

@media (max-width:1490px) and (min-width:768px){
    .box-content h4 {
        height: 76px;
        /* margin-top: 20px; */
    }
}
@media (max-width:768px){
    .about-section {       
        width: 100%;
    }
}

@media (min-width:320px) and (max-width:768px){
    .header-banner{
        position: relative;
        margin-top:33px;
        height: calc(100vh - 33px);
    }
    .logo img{
        width: 150px;
        height: auto;
    }
    .menu-icon{
        display: block;
    }
    .service-section{
        padding:20px 10px;
    }
    .menu-icon i{
        font-size: 30px;
        color:#fff;
    }
    .header > ul{
        position: fixed;
        right: 0;
        top: 0;
        box-shadow: 0 0px 1px rgb(0 0 0 / 20%), 0 4px 10px rgb(0 0 0 / 20%);
        width: 300px;
        z-index: 9999;
        background: var(--primarycolor);
        justify-content: flex-start;
        align-items: flex-start;
        transition: 0.3s all linear;
        transform: translate(100%,0);
        flex-direction: column;
        height: 100vh;
        overflow: auto;
    }
    .header ul li{
        width:100%;
        margin-right:0;
        text-align: left;
    }
    .header > ul > li{
        display: flex;
        align-items: center;
        padding-right: 0;
        box-sizing: border-box;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .header > ul > li:hover .dropdown{
        display:none !important;
    }
    ul.open{
        transform: translate(0) !important;
    }
    .header ul li .dropdown{
        width: 100%;
        position: static;
        background-color: #fff;
        margin-left: 0;
        border-radius: 0;
    }
    
    .header ul li .dropdown ul{
        position: static;
        height:auto;
    }
    .bottom-buttons{
        flex-direction: column;
    }
    
    .header{
        padding:0 15px;
    }
    .speaker-card{
        padding:10px;
    }
    .less-pad{
        padding:20px 30px !important;
    }
    .less-pad .slick-dots{
        bottom: 0;
    }
    .contact-us{
        flex-direction: column;
    }
    .contact-details ul li{
        margin-bottom: 10px;
        margin-left:0;
    }
    .contact-details ul li p{
        text-align: left;
    }
    .contact-details ul{
        flex-direction: column;
        margin-top: 20px;
    }
    .banner-content{
        padding:0 10px;
    }
    .banner-content h2{
        font-size:30px;
    }
    .logic-provider-section{
        padding:20px 10px;
        flex-direction: column;
    }
    .image-right{
        width:100%;
        margin-top: 30px;
    }
    .main-heading{
        font-size:24px !important;
    }
    .services-box{
        width:100%;
        margin-bottom: 30px;
    }
    .card-container{
        flex-direction: column;
    }
    .our-services-section{
        padding:20px 10px;
    }
    .image-right img{
      width:100%;
    }
    .content-left{
        width:100%;
    }
    .about-us-section{
        flex-direction: column;
    }
    .bottom-buttons a{
        margin-left: 0;
        margin-bottom: 20px;
    }
    .clients-logo{
        flex-direction: column;
        align-items: center;
    }
    .clients-logo img {
        width: 100%;
        height: auto;
        max-width: 90%;
        object-fit: cover;
    }
    .our-services-section .slick-list{
        padding:30px 0 !important;
    }
    .our-services-section .slick-slide{
        width:100% !important;
        margin:0;
    }
    .arrow-down{
        color:#fff;
        padding-right: 15px;
        display: flex;
    }
    .arrow-down i{
        color:#fff;
        font-size: 26px;
    }
    .header > ul > li:hover .dropdown-open{
        display: block !important;
    }
    .header > ul > li .dropdown-open{
        display: block !important;
    }
    .clientSlider{
        padding: 20px 30px;
    }
    .clientSlider .slick-dots{
        bottom: 0;
    }
    .overlay{
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: #000;
        opacity: 0.8;
        transform: translate(100%, 0);
        transition: 0.3s all linear;
        z-index: 999;
    }
    .overlay.overlay-open{
        transform: translate(0);
    }
    .closeMenu{
        display: block !important;
        text-align: right !important;
        padding: 10px !important;
    }
    .closeMenu i{
        color: #fff;
        font-size: 26px;
    }
    .column {
        width: 100%;
        display: block;
    }

    /* New CSS for Media query (Mobile devices) */

    .left-footer{
        width: 100%;
    }
    .right-footer{
        width: 100%;
    }

    .showDesktop{
        display: none;
    }
    .showMobile{
        display: initial;
    }
    .footer-social-media-icons{
        margin-top: 15px;
    }

    .quote-form form{
        width: 80%;
    }
}

